@charset "UTF-8";
*, p, span {
    padding: 0
}
#container, body {
    height: 100%;
    width: 100%
}
#container, .buttonB, body, form, img {
    width: 100%
}

.border-colorful, .bordercolorful {
    border-image: url(../images/weChat/cheliangguanli@2x.png) repeat
}

.liright, .pullright {
    float: right
}

* {
    margin: 0
}

.main, body {
    margin: 0 auto
}

html {
    font-size: 625%
}

body {
    white-space: normal;
    word-wrap: break-word;
    vertical-align: middle;
    text-align: center;
    font-family: FZLTXHK--GBK1-0, NotoSansHans, Verdana, Geneva, sans-serif;
    color: #555;
    background-color: #F3F3F3;
    font-size: .16rem;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
}

.flexDiv {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
}

.flexCDiv {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: nowrap;
}

.imgcicleB, .imgcicleC, .liright {
    display: inline-block
}

.flex1 {
    -webkit-box-flex: 1;
    flex: 1
}

.flex1 input[type=text] {
    margin-left: 10px
}

p, span {
    margin: 0
}

a, a:active, a:hover, a:link, a:visited {
    text-decoration: none;
    color: inherit
}

form {
    background-color: #FFF
}

form .listbu li {
    padding: 0 8% 0 0
}

form .listbu input {
    width: 100%;
    height: 41px
}

form .listbu label {
    margin-right: 6px;
    line-height: 41px
}

ul {
    margin: 0;
    padding: 0
}

li {
    list-style: none;
    overflow: hidden;
    border-bottom: #F0F0F0 1px solid
}

/*
li:active, li:hover {
    text-decoration: none;
    filter: alpha(Opacity=60);
    -moz-opacity: .6;
    opacity: .6;
    -webkit-appearance: none
}
*/

textarea:-moz-placeholder {
    color: #C3C3C3 !important
}

textarea::-moz-placeholder {
    color: #C3C3C3 !important
}

textarea:-ms-input-placeholder {
    color: #C3C3C3 !important
}

textarea::-webkit-input-placeholder {
    color: #C3C3C3 !important
}

input:-moz-placeholder {
    color: #C3C3C3 !important
}

input::-moz-placeholder {
    color: #C3C3C3 !important
}

input:-ms-input-placeholder {
    color: #C3C3C3 !important
}

input::-webkit-input-placeholder {
    color: #C3C3C3 !important
}

input[type=text] {
    background-color: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-decoration: none;
    border: none;
    outline: 0
}

select{
    background-color: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-decoration: none;
    border: none;
    outline: 0
}
button, button:active, button:hover {
    -moz-appearance: none;
    text-decoration: none
}

button:active, button:hover {
    filter: alpha(Opacity=80);
    -moz-opacity: .8;
    opacity: .8;
    -webkit-appearance: none;
    appearance: none
}

button {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background-color: inherit;
    -webkit-appearance: none;
    appearance: none;
    color: #fff;
    border: none
}

.list li, .listno li {
    text-align: left;
    background-color: #FFF;
    font-size: .14rem;
    vertical-align: middle;
    clear: both
}

.list, .listbu, .listno, .listqb {
    background: #FFF
}

.buttonB {
    font-size: .14rem;
    padding: 10px 4%
}

.buttonN {
    font-size: .14rem;
    padding: 15px 4%;
    width: 83%
}

.buttonS {
    width: 45.7%;
    height: 30px
}

.list {
    border-bottom: #F0F0F0 1px solid;
    border-top: #F0F0F0 1px solid
}

.list li {
    border-top: none;
    padding: 13px 15px
}

.list li:last-child {
    border-bottom: none
}

.listno {
    padding: 15px 0
}

.listno li {
    border-bottom: none;
    padding: 0 15px
}

.listbu li, .listqb li {
    margin-left: 4%;
    padding: 15px 8% 15px 0;
    font-size: .14rem;
    color: #555;
    text-align: left
}

.listqb {
    border-bottom: #F0F0F0 1px solid;
    border-top: #F0F0F0 1px solid
}

.listqb li:last-child {
    border-bottom: none
}

.listbu, .listqb li {
    border-bottom: #F0F0F0 1px solid
}

.listqb li {
    border-top: none;
    margin-right: 4%
}

.listbu {
    border-top: #F0F0F0 1px solid
}

.listbu li:last-child {
    border-bottom: none
}

.listbu li {
    border-top: none;
    border-bottom: #F0F0F0 1px solid
}

.listbupic {
    margin-left: 18.93% !important
}

.liiconB {
    width: 43px;
    vertical-align: middle;
    padding-right: 14px
}

.liiconBs {
    width: 31px;
    vertical-align: middle;
    padding-right: 21px
}

.liiconS, .liiconSs {
    padding-right: 4px;
    vertical-align: middle
}

.liiconC {
    width: 16px;
    vertical-align: middle
}

.liiconS {
    width: 12px
}

.liiconSs {
    width: 8px
}

.ligengduo {
    background: url(../images/weChat/gengduo@2x.png) 96% center no-repeat;
    background-size: 5px;
    background-origin: padding-box
}

.imgcicle, .imgcicleB, .imgcicleC {
    background: #FFF;
    overflow: hidden
}

.liright {
    font-size: .13rem;
    color: #BABDC2;
    overflow: hidden
}

.lisecond {
    padding-left: 17px
}

li span {
    vertical-align: middle
}

.imgcicleB {
    vertical-align: middle;
    width: 89px;
    height: 89px;
    border: 1px solid #FFF;
    border-radius: 100%
}

.imgcicleC {
    width: 67px;
    height: 67px;
    border-radius: 100%
}

.imgcicle {
    width: 56px;
    height: 56px;
    border: 1px solid #E1E1E1;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%
}

.hasbottom {
    margin-bottom: 15px
}

.hastop {
    margin-top: 15px
}

.hasright {
    margin-right: 15px
}

.hasimg {
    height: 63px;
    line-height: 26px
}

.label {
    border-radius: 3px;
    color: #FFF;
    font-size: .09rem;
    padding: 2px 3px;
    vertical-align: middle
}

.fontGrey13, .fontGrey13f {
    font-size: .13rem !important
}

.hidden {
    display: none
}

.fontGrey13f {
    color: #555
}

.fontGrey13 {
    color: #999
}

.fontGrey9 {
    color: #999;
    font-size: .09rem !important
}

.fontGrey11 {
    color: #999;
    font-size: .11rem !important
}

.fontwarn22 {
    color: #F09595;
    font-size: .22rem !important
}

.color-normal {
    color: #82C458
}

.color-info {
    color: #45A6F6
}

.color-warn {
    color: #F09595
}

.color-default {
    color: #BABDC2
}

.colorRed {
    color: #D35052
}

.colorGreen {
    color: #72C459
}

.bordercolorful {
    -o-border-image: url(../images/weChat/cheliangguanli@2x.png) repeat;
    border-width: 2px
}

.border-left {
    border-left: #F2F2F5 1px solid
}

.borderBottom {
    border-bottom: #E1E1E1 1px solid !important
}

.border-normal {
    color: #82C458;
    border: 1px solid #82C458
}

.border-info {
    color: #45A6F6;
    border: 1px solid #45A6F6
}

.border-warn {
    color: #F09595;
    border: 1px solid #F09595
}

.border-default {
    color: #BABDC2;
    border: 1px solid #BABDC2
}

.border-colorful {
    -o-border-image: url(../images/weChat/cheliangguanli@2x.png) repeat;
    border-width: 2px
}

.bg-normal {
    background-color: #82C458
}

.bg-info {
    background-color: #45A6F6
}

.bg-warn {
    background-color: #F09595
}

.bg-default {
    background-color: #BABDC2
}

.bgred {
    background-color: #D85252
}

.bgwhite {
    background-color: #FFF
}

.bgGreen {
    background-color: #79C661
}
.bgBlue{
    background-color: #6BACD3
}

.bgGrey {
    background-color: #F3F3F3
}

.marL {
    margin-left: 4px
}

.forfix {
    padding:60px 0;
}
.toScan{
    position: fixed;
    width: 100%;
    left: 0px;
    bottom: 20px;
}
.hasline li {
    position: relative;
    border-bottom: none
}

.hasline li:last-child::after {
    height: 90%
}

.hasline li:first-child::before {
    width: 0
}

.hasline li::before {
    content: '';
    position: absolute;
    top: 0;
    left: 4px;
    height: 24px;
    width: 1px;
    background: #D8D8D8;
    z-index: 0
}

.hasline li::after {
    content: '';
    position: absolute;
    top: 24px;
    left: 4px;
    height: 100%;
    width: 1px;
    background: #D8D8D8;
    z-index: 0
}

.togglebutton {
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    width: 50px;
    height: 32px;
    background: #dfdfdf;
    border-radius: 16px;
    border: 1px solid #dfdfdf;
    outline: 0;
    box-sizing: border-box
}

.togglebutton:checked {
    border-color: #04be02;
    background-color: #04be02
}

.togglebutton:after, .togglebutton:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    border-radius: 15px;
    transition: transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s, -webkit-transform .3s;
    -webkit-transition: -webkit-transform .3s
}

.togglebutton:before {
    width: 48px;
    background-color: #fdfdfd
}

.togglebutton:after {
    width: 30px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .4)
}

.togglebutton:checked:before {
    transform: scale(0);
    -webkit-transform: scale(0)
}

.togglebutton:checked:after {
    transform: translateX(17px);
    -webkit-transform: translateX(17px)
}

.gouuck {
    display: inline-block;
    border: 1px solid #E1E1E1;
    color: #999;
    font-size: .12rem;
    padding: 4px 14px;
    margin: 6px 10px;
    border-radius: .03rem
}

.goucheck {
    border: 1px solid #79C761;
    color: #79C761;
    background: url(../images/weChat/xuanzhong@2x.png) right bottom no-repeat;
    background-size: 15px 10px
}

.duigou::before {
    content: '\2714';
    font-size: 14px
}

.cicleCek::before {
    display: inline-block;
    content: '\2714️';
    color: #fff;
    font-size: 8px;
    line-height: 15px;
    width: 15px;
    height: 15px;
    background-color: #72C459;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%
}

.titleN {
    display: block;
    text-align: left;
    padding: 10px 4%;
    color: #999;
    font-size: .12rem;
    background: #F3F3F3
}

.msgboxO, .msgboxT {
    text-align: center
}

.noline {
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}



.msgboxO {
    padding: 25px 6.7%;
    background: #fff;
    border-radius: 10px;
    border: none;
    position: fixed;
    left: 6%;
    top: 19%;
    width: 74.6%;
    z-index: 10
}

.msgboxO .msgbox-title {
    margin-bottom: 15px;
    color: #797979;
    line-height: 44px
}

.msgboxO .msgbox-title img {
    width: 44px;
    vertical-align: middle
}

.msgboxO .msgbox-title span {
    color: #797979;
    font-size: .18rem;
    vertical-align: middle
}

.msgboxO .msgbox-content {
    color: #999;
    font-size: .13rem;
    line-height: 22px;
    margin-bottom: 15px;
    padding: 0px 6.7%;
}

.alertBox {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    z-index: 11
}

.alertBox .msgboxO {
    position: absolute;
    left: 13%;
    top: 19%;
    padding: 0px;
    width: 74%;
}
.alertBox .msgboxO .msgbox-content {
    color: #646471;
    font-size: .13rem;
    line-height: 22px;
    margin-bottom: 32px;
    margin-top: 32px;
}

.alertBox .msgboxO .msgbox-foot {
    border-top: #F0F0F0 1px solid;
    color: #72C459;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 0.15rem;
}

.msgboxT {
    background: #fff;
    border: none;
    position: fixed;
    left: 4%;
    top: 52px;
    width: 92%;
    color: #646471;
    font-size: .12rem;
    z-index: 10
}

.msgboxT .msgbox-top {
    height: 251px;
    padding: 25px;
    border-bottom: #F2F2F5 1px solid
}

.msgboxT .msgbox-bottom {
    height: 95px;
    text-align: left
}

.msgboxT .msgboxT::before {
    content: "";
    position: absolute;
    left: 0;
    top: 301px;
    border: 1px solid #000
}

.haspage {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none
}

.nextpage, .refresh {
    overflow: hidden
}

.typing_loader {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    -webkit-animation: loadering 1s linear infinite alternate;
    -moz-animation: loadering 1s linear infinite alternate;
    animation: loadering 1s linear infinite alternate;
    margin: 20px auto;
    position: relative;
    left: -12px
}

@-webkit-keyframes loadering {
    0% {
        background-color: #999;
        box-shadow: 12px 0 0 0 rgba(153, 153, 153, .2), 24px 0 0 0 rgba(153, 153, 153, .2)
    }
    25% {
        background-color: rgba(153, 153, 153, .4);
        box-shadow: 12px 0 0 0 #999, 24px 0 0 0 rgba(153, 153, 153, .2)
    }
    75% {
        background-color: rgba(153, 153, 153, .4);
        box-shadow: 12px 0 0 0 rgba(153, 153, 153, .2), 24px 0 0 0 #999
    }
}

@-moz-keyframes loadering {
    0% {
        background-color: #999;
        box-shadow: 12px 0 0 0 rgba(153, 153, 153, .2), 24px 0 0 0 rgba(153, 153, 153, .2)
    }
    25% {
        background-color: rgba(153, 153, 153, .4);
        box-shadow: 12px 0 0 0 #999, 24px 0 0 0 rgba(153, 153, 153, .2)
    }
    75% {
        background-color: rgba(153, 153, 153, .4);
        box-shadow: 12px 0 0 0 rgba(153, 153, 153, .2), 24px 0 0 0 #999
    }
}

@keyframes loadering {
    0% {
        background-color: #999;
        box-shadow: 12px 0 0 0 rgba(153, 153, 153, .2), 24px 0 0 0 rgba(153, 153, 153, .2)
    }
    25% {
        background-color: rgba(153, 153, 153, .4);
        box-shadow: 12px 0 0 0 #999, 24px 0 0 0 rgba(153, 153, 153, .2)
    }
    75% {
        background-color: rgba(153, 153, 153, .4);
        box-shadow: 12px 0 0 0 rgba(153, 153, 153, .2), 24px 0 0 0 #999
    }
}
.fenge{
    margin: 0px auto;
    width: 70%;
    color: #888;
    font-size: .12rem;
    img{
        width: 100%;
        padding: 24px 0px;
    }
}

.bikebutton {
    padding: 15px 0;
    background: #FFF;
    width: 100%;
    overflow: hidden;
    font-size: .14rem;
    color: #555;
    margin-bottom: 13px
}

.bikebutton div {
    border-right: #DFDEDC 1px solid
}

.bikebutton div:last-child {
    border-right: none
}

.bikebutton img {
    width: 20px;
    vertical-align: middle
}

.bikebutton span {
    line-height: 20px;
    vertical-align: middle;
    margin-left: 2px
}

.titlestation {
    background: url(../images/nanY/zhandian@2x.png) left top no-repeat;
    background-size: 17px;
    color: #555;
    font-size: .15rem;
    padding-left: 27px;
    line-height: 17px
}

#rightM {
    width: 60%
}

.bicyleInfoNY {
    width: 100%;
    position: relative;
    background: #1b1b1f;
    overflow: hidden;
    padding: 13px 0;
    color: #FFF
}

.bicyleInfoNY .detailbikeshou {
    float: left;
    margin-top: 0;
    text-align: left;
    font-size: .14rem;
    line-height: 25px;
    padding-left: 9px
}

.bicyleInfoNY .detailimg {
    margin: 0 15px;
    max-width: 75px;
    max-height: 76px;
    float: left;
    overflow: hidden;
    position: relative;
    border: 1px solid #E1E1E1
}

.bicyleInfoNY .bikecode {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #000;
    color: #FFF;
    font-size: .12rem;
    filter: alpha(opacity=70.19607843137254);
    opacity: .7019607843137254;
    z-index: 10;
    display: inline-block;
    width: 76px;
    text-align: center
}

.bicyleInfoNY .mine {
    overflow: hidden;
    width: 100%;
    height: 30px;
    text-align: right;
    display: inline-block
}

.bicyleInfoNY .mine img {
    width: 30px
}

.grexplainpad {
    padding: 25px 0 48px;
    color: #999;
    font-size: .13rem;
    display: block;
    background: 0 0
}

.user_terms_check {
    font-size: .13rem;
    color: #999;
    text-align: center;
    line-height: 14px;
    margin-bottom: 12px
}

.user_terms_check label {
    background: url(../images/nanY/gou@2x.png) left center no-repeat;
    background-size: 14px;
    padding-left: 17px
}

.user_terms label {
    background: url(../images/nanY/gou_hui@2x.png) no-repeat;
    background-size: 14px;
    padding-left: 17px
}

.bicycleInfopofix {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #FFF;
    width: 90%;
    text-align: left;
    padding: 12px 5%;
    border-bottom: #E1E1E1 1px solid;
    border-top: #E1E1E1 1px solid;
    z-index: 20;
    filter: alpha(opacity=95);
    opacity: .95;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-decoration: none;
    outline: 0
}

.bicyleInfo a {
    color: #30C665
}

.bicycleInfopofix input[type=button] {
    background-color: #FB4E44;
    width: 119px;
    color: #FFF;
    font-size: .15rem;
    display: block;
    height: 30px;
    float: right;
    border-radius: .02rem;
    -moz-border-radius: .02rem;
    -webkit-border-radius: .02rem;
    border-style: none
}

.divR, .errortips {
    -webkit-border-radius: .03rem
}

.NYtitle {
    text-align: left;
    margin: 10px 4% 5px;
    padding-left: 15px;
    border-left: #FF8277 3px solid;
    font-size: 16px
}

.divR, .divRb {
    padding: 5px 1%
}

.divR {
    text-align: center;
    background: #FF8277;
    color: #fff;
    width: 34%;
    margin: 10px 7%;
    -moz-border-radius: .03rem;
    border-radius: .03rem;
    float: left
}

.divR_checked {
    background: #999;
    color: #fff
}

.divRb {
    border: 1px solid #FF8277;
    text-align: left;
    font-size: .12rem;
    color: #555;
    background: #fff;
    margin: 10px 5%;
    -moz-border-radius: .03rem;
    -webkit-border-radius: .03rem;
    border-radius: .03rem;
    clear: both
}

.shuaccountNY, .shuaccountNY input[type=text], .shuaccountNY textarea {
    font-size: .15rem;
    color: #555;
    line-height: 20px
}

.shutitleNY {
    margin-left: 5px;
    margin-top: 5px;
    display: inline-block
}

.shuaccountNY {
    padding: 10px 19px 15px;
    position: relative;
    vertical-align: top
}

.shuaccountNY textarea {
    width: 84%;
    outline: 0;
    border: none;
    background-color: inherit
}

.shuaccountNY input[type=text] {
    width: 84%
}

.accountNY form {
    margin: 16px 4% 45px;
    background: #FFF;
    overflow: hidden;
    text-align: left;
    width: 92%
}

.errortips {
    display: none;
    -moz-border-radius: .03rem;
    border-radius: .03rem;
    width: 80%;
    color: #FFF;
    font-size: .14rem;
    padding: 3px;
    background: #000;
    filter: alpha(Opacity=80);
    -moz-opacity: .8;
    opacity: .8;
    margin: 0 auto;
    clear: both;
    position: fixed;
    top: 30%;
    left: 10%;
    z-index: 100
}

#myCanvas, .imgcheck {
    display: inline-block;
    overflow: hidden
}
#imgcheck{
    width: 159px;
    border-right: #DADADA 1px solid;
    height: 45px;
    display: inline-block;
    vertical-align: middle;
}
#imgCode{
 width: 50%;
}

.error {
    color: red;
    font-size: .13rem
}

.nomessage {
    padding-top: 139px;
    color: #999;
    font-size: .13rem;
    width: 100%;
    text-align: center
}

.regicheck, .regimobile {
    font-size: .14rem;
    color: #3f3f3f;
    height: 45px;
    text-align: center
}

.nomessage img {
    width: 169px
}

.regiback {
    background: #fff
}

.regiback .buttonN {
    width: 80.8%;
    padding: 12.5px 15px
}

.regiback .listno li {
    padding: 0
}

.regilogo {
    width: 173px;
    margin: 54px auto 58px
}

#register_form {
    width: 80.8%;
    margin: 0 auto
}

#register_form input[type=text] {
    background: #F3F3F3;
    border: none;
    border-radius: 3px;
    height: 45px
}

.regimobile {
    width: 100%
}

.regicheck {
    width: 71%
}

#checkbutton{
    width: 25.6%;
    border-left: #DADADA 1px solid;
    height: 45px;
    display: inline-block;
    vertical-align: middle;
}

#checkbutton {
    color: #79C661;
    font-size: .12rem;
    background: 0 0
}

#checkbutton[disabled=true], #checkbutton[disabled=disabled] {
    color: #a5a5a5;
    font-size: .12rem;
    border-left: #DADADA 1px solid;
    width: 25.6%;
    height: 30px;
    background: 0 0
}



.register {
    border: none;
    background: #F3F3F3;
    margin: 0 auto;
    text-align: left;
    border-radius: 3px
}

.regigrexplain {
    color: #999;
    font-size: .1rem;
    padding: 0 0 21px;
    margin: 0 auto
}

.regigrexplain a {
    color: #30C665
}

.stationDetail .header {
    background: url(../images/weChat/bj_zhandian@2x.png) top left no-repeat;
    background-size: 100% 184.5px
}

.stationDetail .header img {
    margin-top: 33px;
    width: 73px;
    height: 73px;
    border: 1px solid #FFF;
    border-radius: 100%
}

.stationDetail .pierSum {
    background: #fff;
    color: #999;
    font-size: .11rem;
    padding: 14px 0
}

.stationDetail .pierSum span {
    color: #646471;
    font-size: .17rem
}

.stationDetail li span {
    color: #2D2A49;
    font-size: .13rem
}


.stationDetail .shop p {
    padding: 13px 10px 0 15px;
    color: #B3B3BA;
    font-size: .11rem;
    line-height: 17px
}

.stationDetail .shop .call {
    padding: 3px 5px;
    border-radius: 3px;
    border: 1px solid #2D2A49;
    color: #2D2A49;
    font-size: .09rem
}

.stationDetail .pullbottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index:2;
}

.stationDetail .pullbottom button {
    width: 147px;
    border: 1px solid #646471;
    height: 27px;
    margin: 8px;
    color: #646471;
    font-size: .14rem
}

.windowContent {
    padding: 15px;
    line-height: 16px;
    width: 135px;
    height: 43px;
    overflow: hidden
}
.windowContent p, .windowContent span {
    white-space: normal;
    word-wrap: break-word
}

.windowContent span {
    color: #2D2A49;
    font-size: .13rem
}

.windowContent p {
    color: #A1A1A1;
    font-size: .11rem
}

.windowContentV {
    padding: 10px;
    width: 200px;
    height: 126px;
    overflow: hidden;
}
.windowContentV p, .windowContentV span {
    white-space: normal;
    word-wrap: break-word
}

.windowContentV span {
    color: #2D2A49;
    font-size: .13rem
}

.windowContentV p {
    color: #A1A1A1;
    font-size: .11rem
}
.windowContentV .pierSum{
    text-align: center;
    margin-bottom: 5px;
    margin-top: 5px;
}


.pierList {
    padding: 15px 4%;
    color: #646471;
    font-size: .12rem
}

.pierList div {
    width: 22%;
    float: left;
    overflow: hidden;
    background: #FFF;
    margin: 11px 5px 5px;
    padding: 15px 4%;
    border-radius: 3px;
    box-shadow: 0 1px 10px -6px #000
}

.pierList div img {
    width: 57px;
    height: 57px
}

.pierList div p {
    padding: 15px 0 9px
}

.pierList div button {
    width: 100%;
    border: 1px solid #646471;
    color: #646471
}

.pierList .rent button {
    background: #72C459;
    color: #FFF;
    border: 1px solid #72C459
}

.header {
    background: url(../images/weChat/bj_wo@2x.png) top left no-repeat;
    background-size: 100% 184.5px;
    overflow: hidden;
    text-align: center;
    height: 184.5px
}

.header img {
    margin-top: 20px
}

.header span {
    color: #fff;
    font-size: .14rem;
    display: block;
    margin-top: 17px
}

.userinfo form .fileopen {
    position: relative;
    float: right;
    width: 50px;
    height: 50px;
    background: url(../images/weChat/moren_touxiang@2x.png) top center no-repeat;
    background-size: 50px;
    overflow: hidden;
    border-radius: 100%;
    margin: 10px
}

.userinfo form input[type=file] {
    width: 100%;
    height: 100%;
    border: none;
    outline: 0;
    opacity: 0;
    display: block
}

.account {
    color: #555;
    font-size: .15rem;
    background: #fff
}

.account .textIn {
    font-size: .14rem;
    padding: 15px 4%;
    width: 83%;
    margin: 4%;
    background: #f3f3f3;
    border-radius: 3px;
    text-align: center
}

.account .titleN {
    padding: 15px 4%;
    color: #555;
    font-size: .15rem;
    background: #fff
}

.account .Withdrawals_form .titleN {
    padding: 27px 4%
}

.account .grexplain {
    margin: 19px 4%;
    width: 92%;
    text-align: right;
    color: #999;
    font-size: .12rem
}

.account .buttonN {
    width: 90%
}

.account .channel {
    margin: 38px 4% 60px;
    text-align: left
}

.account .channel li {
    padding: 22px 0;
    border: none;
    position: relative
}

.account .channel li input[type=radio] {
    -webkit-appearance: none;
    appearance: none;
    outline: 0;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: url(../images/weChat/weixuanzhong@2x.png) right center no-repeat;
    background-size: 14px;
    border: none;
    -moz-appearance: none
}

.account .channel li input[type=radio]:checked {
    background: url(../images/weChat/xuanzhong@2x.png) right center no-repeat;
    background-size: 14px
}

.accountmoney {
    clear: both;
    overflow: hidden;
    margin-top: 6px;
    padding: 0 3%
}

.accountmoney button {
    width: 46%;
    float: left;
    padding: 15px 5%;
    margin: 6px 2%;
    color: #555;
    font-size: .15rem
}

.accountList {
    font-size: .15rem;
    color: #555;
    padding: 13px 0 !important
}

.accountList .flex1 {
    font-size: .14rem;
    text-align: center;
    border-right: #E1E1E1 1px solid;
    vertical-align: middle
}

.accountList .flex1:last-child {
    border-right: none;
    font-size: .12rem
}

.tradeList {
    overflow: hidden
}

.tradeList .tradeDeteil {
    padding-left: 5%;
    height: 64px;
    text-align: left;
    font-size: .15rem;
    color: #555;
    background: -webkit-linear-gradient(#E1E1E1 1px, transparent 1px, transparent) no-repeat;
    background: linear-gradient(#E1E1E1 1px, transparent 1px, transparent) no-repeat;
    background-color: #fff;
    background-origin: content-box
}

.rentList, .tradeList .titleN + .tradeDeteil {
    background: #fff
}

.tradeList .flex1 + div {
    padding-right: 4%
}

.mine {
    float: right;
    margin: 15px 9px;
    overflow: hidden;
    width: 42px;
    height: 42px;
    display: inline-block;
}

.hasbg {
    background: url(../images/weChat/bj@2x.png) top left no-repeat #6BACD3;
    background-size: 100%
}

.bicycleBox {
    left: 6%;
    top: 109px
}
@media screen and (max-height: 480px){
    .bicycleBox {
        left: 6%;
        top: 79px
    }
    #bicycleinfo {
        .bikeinfo {
            padding: 15px 15px;
        }
        .msgbox-title {
            margin-top: 11px;
        }
    }

}

.bicycleBox::before {
    position: absolute;
    width: 100%;
    height: 67px;
    top: -33.5px;
    left: 0;
    content: "";
    background: url(../images/weChat/xinxiqueren@2x.png) center center no-repeat;
    background-size: 67px 67px
}

.loading_lockOpen {
    width: 150px;
    height: 4px;
    border-radius: 2px;
    margin: 37px auto 52px;
    position: relative;
    background: #90ee90;
    -webkit-animation: changeBgColor 1.04s ease-in infinite alternate;
    animation: changeBgColor 1.04s ease-in infinite alternate
}

.loading_lockOpen span {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #90ee90;
    position: absolute;
    margin-top: -7px;
    margin-left: -83px;
    -webkit-animation: changePosition 1.04s ease-in infinite alternate;
    animation: changePosition 1.04s ease-in infinite alternate
}

.hasfailed .liiconBs, .hasprerent .liiconBs {
    width: 39px
}

@-webkit-keyframes changeBgColor {
    0% {
        background: #90ee90
    }
    100% {
        background: #add8e6
    }
}

@-webkit-keyframes changePosition {
    0% {
        background: #90ee90
    }
    100% {
        margin-left: 67px;
        background: #add8e6
    }
}

#passTip {
    margin-top: 15px
}

.bikeinfo {
    padding: 24px 15px;
    border-top: #E1E1E1 1px solid;
    border-bottom: #E1E1E1 1px solid;
    margin-bottom: 21px
}

.kuang {
    border: 1px dashed #999;
    margin: 2%;
    color: #82C458;
    font-size: .25rem;
    padding: 5%;
    height: 23px
}

#lockOpenFail .fontGrey13 {
    padding-bottom: 28px;
    padding-top: 18px
}

#lockOpenFail .fontGrey13 .fontwarn22 {
    margin: 0 10px
}

#lockOpenShow .msgbox-title {
    margin-top: 31px
}

#bicycleinfo .msgbox-title {
    margin-top: 21px
}

.rentList {
    color: #999;
    font-size: .12rem;
    text-align: left;
    padding: 0 16px;
    line-height: 25px
}

.hasrent .kaisuo, .huafei, .renttime, .shichang {
    padding-left: 15px
}

.rentList li {
    padding-top: 10px
}

.rentList li span {
    position: relative;
    z-index: 2
}

.rentList .flexDiv {
    margin-left: 10px
}

.rentList .flexDiv div {
    -webkit-box-flex: 1;
    flex: 1
}

.renttime {
    background: url(../images/weChat/xingcheng_molan@2x.png) left center no-repeat;
    background-size: 8px;
    font-size: .13rem;
    color: #555;
    padding-right: 4px
}

.huafei {
    background: url(../images/weChat/huafei@2x.png) left center no-repeat;
    background-size: 11px
}

.shichang {
    background: url(../images/weChat/shichang@2x.png) left center no-repeat;
    background-size: 11px
}

.hasfailed .renttime {
    background: url(../images/weChat/xingcheng_lan@2x.png) left center no-repeat;
    background-size: 8px
}

.hasprerent .renttime {
    background: url(../images/weChat/xingcheng_lv@2x.png) left center no-repeat;
    background-size: 8px
}

.hasrent .renttime {
    background: url(../images/weChat/xingcheng_xlv@2x.png) left center no-repeat;
    background-size: 8px
}

.hasrent .huafei {
    background: url(../images/weChat/huafei_lv@2x.png) left center no-repeat;
    background-size: 11px
}

.hasrent .shichang {
    background: url(../images/weChat/shichang_lv@2x.png) left center no-repeat;
    background-size: 11px
}

.hasrent .kaisuo {
    background: url(../images/weChat/kaisuomima@2x.png) left center no-repeat;
    background-size: 11px;
    color: #75C55D
}

.bicycleDetail {
    position: relative;
    box-shadow: 0 8px 6px -6px #e5e5e5
}

.bicycleDetail::before {
    position: absolute;
    width: 100%;
    height: 75px;
    top: -37.5px;
    left: 0;
    content: "";
    background: url(../images/weChat/xingcheng-tou@2x.png) center center no-repeat;
    background-size: 75px 75px;
    z-index: 10;
}


.bicycleDetail .msgbox-top {
    text-align: center;
    padding: 22px;
    border-bottom: #D8D8D8 1px dashed;
    height: 312px;
}

.bicycleDetail .msgbox-top #tradestate {
    font-size: .11rem;
    width: 50px;
    margin: 36px auto 20px auto;
    line-height: 18px;
}

.bicycleDetail .msgbox-top .password{
    background: url(../images/weChat/quan@2x.png) top center no-repeat;
    background-size: 120px 120px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 34px;
}

.bicycleDetail .labelgrey{
    color: #999999;
    font-size: 0.11rem;
    background-color: #f5f5f5;
    padding: 4px 9px;
    border-radius: 3px;
}

.bicycleDetail .msgbox-top .greenB {
    margin-bottom: 10px;
    margin-top: 15px;
    color: #72C459;
    font-size: .30rem;
    line-height: 30px
}

.bicycleDetail .msgbox-top .greenS {
    color: #72C459;
    font-size: .11rem
}


.bicycleDetail .msgbox-top .flexDiv {
    margin-top: 20px;
    color: #646471;
    font-size: .17rem
}

.bicycleDetail .msgbox-top::before {
    position: absolute;
    content: "";
    background: #F3F3F3;
    width: 11px;
    height: 11px;
    top: 345px;
    left: -3px;
    border-top: #fff 15px solid;
    border-right: #fff 15px solid;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.bicycleDetail .msgbox-top::after {
    position: absolute;
    content: "";
    background: #F3F3F3;
    width: 11px;
    height: 11px;
    top: 345px;
    right: -3px;
    border-bottom: #fff 15px solid;
    border-left: #fff 15px solid;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.middleDiv{
    position: absolute;
    width: 100%;
    height: 19px;
    bottom: 85.5px;
    left: 0px;
}
.bicycleDetail .msgbox-bottom div {
    -webkit-box-flex: 1;
    flex: 1;
    padding: 31px 0 31px 32px;
    margin-left: 25px
}

.bicycleDetail .msgbox-bottom .stationS {
    background: url(../images/weChat/qidian@2x.png) left center no-repeat;
    background-size: 23px
}

.bicycleDetail .msgbox-bottom .stationE {
    background: url(../images/weChat/zhongdian@2x.png) left center no-repeat;
    background-size: 23px
}

.bicycleFault {
    width: 100%;
    bottom: 0px;
    text-align: center;
    position: fixed;
    left: 0px;
    background: #ffffff;
    color: #999999;
    font-size: 0.13rem;
    z-index: 13;
}

.bicycleFault .greenS {
    color: #72C459;
    font-size: .11rem
}

.bicycleFault .flex1{
    margin: 16px 0;
    border-left: #F2F2F5 1px solid;
}
.bicycleFault .flex1:first-child {
    border-left: none;
}

.aboutUs .flex1{
    padding-left: 5px;
    text-align: right;
}
.aboutUs .logo {
    margin: 54px auto 27px;
    width: 71px;
}

.aboutUs .pullbottom {
    position: fixed;
    bottom: 43px;
    left: 0;
    width: 100%
}

.myaccount {
    color: #fff;
    background: url(../images/weChat/qianbao_bj@2x.png) left top no-repeat;
    background-size: 100%;
    overflow: hidden
}
.myaccount .contentTop{
    position: absolute;
    z-index: 3;
    width: 100%;
    left: 0px;
    top: 0px;
}

.myaccount .sumaccount {
    margin: 106px auto 44px;
}

.myaccount .sumaccount span {
    font-size: .42rem
}

.myaccount .pullbottom {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 2;
    img{display: block;}
    p{
        position: absolute;
        width: 100%;
        bottom: 60px;
        left: 0px;
    }
}

.mesList .nomessage {
    padding-top: 139px;
    color: #999;
    font-size: .15rem;
    width: 100%;
    text-align: center
}

.mesList .nomessage img {
    width: 73px;
    padding-bottom: 52px
}

.message .account {
    color: #fff;
    float: right;
    padding: 1px 2px;
    font-size: .11rem;
    border-radius: 18px;
    background: #D04057
}

.operation {
    padding: 15px 4%
}

.operation .upPics {
    float: left
}

.operation .upPics div {
    position: relative;
    line-height: 0;
    margin-right: 15px;
    border: 1px solid #CACACA;
    float: left
}

.operation .upPics div .fileView {
    width: 76px;
    height: 76px
}

.operation .upPics div .delbutton {
    width: 13px;
    height: 13px;
    position: absolute;
    right: -6.5px;
    top: -6.5px;
    z-index: 2
}

.operation .bgwhite {
    padding: 15px;
    overflow: hidden
}

.operation form {
    position: relative;
    background: 0 0
}

.operation form input[type=text] {
    width: 92%;
    background: #fff;
    padding: 15px 4%;
    text-align: center;
    border-radius: 3px;
    font-size: .14rem
}

.operation form .sao {
    width: 31px;
    z-index: 2;
    position: absolute;
    right: 7px;
    top: 7px
}

.operation form .fileopen {
    position: relative;
    float: left;
    width: 76px;
    height: 76px;
    border: 1px dashed #E1E1E1;
    background: url(../images/weChat/jia@2x.png) center center no-repeat #F6F6F6;
    background-size: 23px;
    overflow: hidden;
    clear: right
}

.operation form input[type=file] {
    width: 100%;
    height: 100%;
    border: none;
    outline: 0;
    opacity: 0;
    display: block
}

.operation form textarea {
    font-size: .14rem;
    border: none;
    border-radius: 3px;
    width: 92%;
    background: #fff;
    padding: 15px 4%
}

.operation form .flexCDiv {
    background: #fff
}

.operation form .flexCDiv label {
    padding: 0 10px;
    font-size: .13rem
}

.operation form .flexCDiv + .errorTip {
    margin: 0;
    line-height: 0
}

.operation form #producttype {
    background: #fff;
    width: 100%;
    border: none;
    padding: 15px 4%;
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none
}

.advertTip {
    background-color: #fff;
    color: #72C459;
    font-size: .11rem;
    padding: 9px 0 9px 13px;
    width: 68.3%;
    margin: 15px 0 15px 15px;
    float: right;
    line-height: 16px;
    border-radius: .03rem;
    text-align: left
}

.advertTip span {
    vertical-align: middle
}

.noConcern img {
    width: 231px;
    vertical-align: middle
}

.noConcern button {
    width: 69%;
    vertical-align: middle;
    height: 48px;
    padding: 0
}

.advertBg {
    color: #fff;
    font-size: .11rem;
    background-color: #BF1115;
    margin: 0 auto
}

.advertBg .content {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%
}

.advertBg .content .adto_button {
    margin-top: 8px
}

.advertBg .content p {
    margin-bottom: 9px;
    margin-top: 6px
}

.advertBox {
    background: rgba(0, 0, 0, .8);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0
}

.advertBox .advertMain {
    width: 80%;
    position: absolute;
    left: 10%;
    top: 65px
}

.advertBox #advert_guanbi {
    margin-top: 33px;
    width: 21px
}

.advert_recharge {
    background: #FFEFE0;
    color: #555;
    font-size: .11rem
}

.advert_recharge .advert_reTip {
    width: 71.7%;
    padding: 30px 15% 0;
    text-align: left
}

.adto_button {
    width: 73%;
    padding: 0 13.5%
}

.topFixed{
   position: fixed;
    left: 0px;
    top: 0px;
    width: 91%;
    overflow: hidden;
    z-index: 10;
}
.bottomFixed{
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    overflow: hidden;
    z-index: 10;
}
.stationView{
    padding: 0 4.5%;
    color: #505050;
    font-size: 0.14rem;
    background: #ffffff;
    .viewStation{
        text-align: left;
        border-bottom: #D8D8D8 1px solid;
        .station{background: url(../images/weChat/zhandianicon@2x.png) left center no-repeat;
            background-size: 15px;
            background-origin: padding-box;
            padding-left: 21px;
            line-height: 42px;}
        .bike{
            background: url(../images/weChat/bikeicon@2x.png) left center no-repeat;
            background-size: 15px;
            background-origin: padding-box;
            padding-left: 21px;
            line-height: 42px;
        }
        p{font-size: 0.11rem;
            margin-top: 3px;
            margin-bottom: 15px;}
    }
    #editDevice{
        color: #505050;
        border:#525252 1px solid;
    }
    #closeDevice{
        color: #727272;
        border:#CCCCCC 1px solid;

    }
    .pierSum{
        border-bottom: #D8D8D8 1px solid;
        .flex1{
            border-left: #D8D8D8 1px solid;
            margin-bottom: 17px;
            margin-top: 17px;
            text-align: center;
            span{ font-size: 0.15rem;color: #525252}
            p{font-size: 0.12rem;color: #555555}

        }
        .flex1:first-child{
            border-left: none;
        }
    }
    .buttonS{
        margin: 15px 0px;
        width: 79px;
        font-size: 0.12rem;
    }
}
#cerForm{
    input{
        background: #F3F3F3;
        width: 90%;
        line-height: 40px;
        padding: 5px 5%;
        margin-bottom: 5px;
        border-radius: 3px;
    }
}

// 蓝牙
.bicycleStateDesc{
    font-size: 0.11rem;
    line-height: 10px;
    color: #45A6F6;
}

.huizong{
    position: relative;
    background: #45A6F6;
    color: #fff;
    font-size: 0.11rem;
    a{
        position: absolute;
        right: 24px;
        top: 15px;
     }
    .feeSum{
        padding-top: 46px;
        width: 100%;
        text-align: center;
        .allfee{
            font-size: 0.34rem;
            padding: 15px 0 26px 0px ;
        }
    }
    .flexCDiv{
        background-color: rgba(0,0,0,0.2);
        .flex1{
            margin: 9px 0px;
            p{font-size: 0.11rem; color: #fff;}
            span{font-size: 0.15rem;}
        }
        .shu{
            font-size: 0.26rem;
            line-height: 26px;
        }
    }

}
.search{
    line-height: 20px;
    label{
        margin-right: 10px;
    }
    input{
        width: 100%;
        height: 20px;
        border: none;
        -webkit-tap-highlight-color:transparent;
    }
}
.sumTable {
    background: #ffffff;
    font-size: 0.13rem;
    border-collapse: collapse;
    // table-layout:fixed;
    word-break:break-all;
    width: 100%;
    .nomessage{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    tr{
        border-bottom: 1px solid #E5E5E5;
    }
    td{
        padding: 10px 5px;
        min-width: 40px;
    }
    th{
        padding: 10px 0;
    }
    thead{
        /*
        background: #607fa1;
        */
        color: #999999;
        font-size: 0.14rem;
        td{
            padding: 16px 0;
        }
    }
    tbody{
        color: #555555;
    }
}

.toIndex{
    position: fixed;
    right: 10px;
    top: 15px;
    width: 50px;
    z-index: 12;
}