.hasbgWC{
  background: url(../images/weChat/sao_bj3@2x.png) bottom left no-repeat,
  url(../images/weChat/sao_bj2@2x.png) bottom left no-repeat,
url(../images/weChat/sao_bj@2x.png) top left repeat;
  background-size: 100%, 100%,7%;
  /*

  */
  background-color: #6BACD3;
   // background-size: 7%;
}
.wheelchair{
  .mineWC{
    position: fixed;
    right: 0px;
    top: 24px;
    border-radius: 5px 0 0 5px;
    background: rgba(0,0,0,0.5);
    color: #ffffff;
    font-size: 0.13rem;
    padding: 9px 12px;
  }
  .titleWC{
    color:#555555;
    text-align: left;
    font-size: 0.15rem !important;
  }
  .showImgWC{
    background: #ffffff;
    img{
      display: block;
    }
  }
  .detailMch{
    p{margin-top: 8px;}
    .liiconS{
      width: 14px;
    }
  }
  .listbu li{
    color: #555555;
    font-size: 0.14rem;
    padding-top: 11px;
    padding-bottom: 11px;
    text-align: left;
.pullright{
  font-size: 0.13rem;
}
    span{
      font-size: 0.14rem;
    }
    p{
      font-size: 0.11rem;
      color: #999999;
    }
    .phone{
      border-left: 1px solid #e1e1e1;
      padding: 5.5px 0 5.5px 4.245%;
      width: 12px;
    }
  }
  button{
    width: 100%;
   border-radius: 0 !important;
  }
  buttonB{
    padding: 14px 4%;
  }
}
