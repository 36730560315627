/**
 * Created by Lj on 2017/11/6.
 */
.yyl {
  width: 100%;
  position: relative;
  text-align: center;
  font-size: 0.13rem;
  color: #1F1F1F;
  .bg {
    width: 100%;
    display: block;
  }
  .yyl_sz {
    position: absolute;
    width: 86.93%;
    left: 8.5%;
    top: 32px;
  }
  .yyl_y {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
  }
  .yyl_bt {
    position: absolute;
    top: 346px;
    left: 23.85%;
    width: 52.3%;
  }
  #jian {
    width: 4.3%;
    position: absolute;
    left: 24%;
  }
  #jia {
    width: 4.3%;
    position: absolute;
    right: 24%;
  }
  #yyl_tcy {
    position: absolute;
    top: 150px;
    left: 25.6%;
    width: 49.06%;
  }
  #yyl_fwtk {
    position: absolute;
    width: 100%;
    top: 324px;
    left: 0px;
    font-size: 0.09rem;
    color: #494949;
    span {
      color: #4CACE3;
    }
  }

  #yyl_yb {
    position: absolute;
    top: 200px;
    left: 35.85%;
    width: 28.3%;
    transition: All 1.5s ease-in-out;
    -webkit-transition: All 1.5s ease-in-out;
    -moz-transition: All 1.5s ease-in-out;
    -o-transition: All 1.5s ease-in-out;
  }

  #yyl_gou {
    position: absolute;
    top: 149px;
    width: 17.06%;
    left: 41.47%;
  }
  #yyl_zfcg {
    position: absolute;
    top: 235px;
    width: 36.8%;
    left: 31.6%
  }
  #yyl_sm {
    position: absolute;
    left: 0px;
    width: 100%;
    position: absolute;
  }
  .zhuan {
    -webkit-animation: typing 1s linear infinite alternate;
    -moz-animation: Typing 1s linear infinite alternate;
    animation: typing 1s linear infinite alternate;
  }

  @-webkit-keyframes typing {
    0% {
      transform: rotateY(180deg);
      -ms-transform: rotateY(180deg);
      /* IE 9 */
      -moz-transform: rotateY(180deg);
      /* Firefox */
      -webkit-transform: rotateY(180deg);
      /* Safari 和 Chrome */
      -o-transform: rotateY(180deg);
      /* Opera */
    }
    25% {
      transform: rotateY(360deg);
      -ms-transform: rotateY(360deg);
      /* IE 9 */
      -moz-transform: rotateY(360deg);
      /* Firefox */
      -webkit-transform: rotateY(360deg);
      /* Safari 和 Chrome */
      -o-transform: rotateY(360deg);
      /* Opera */
    }
    50% {
      transform: rotateY(180deg);
      -ms-transform: rotateY(180deg);
      /* IE 9 */
      -moz-transform: rotateY(180deg);
      /* Firefox */
      -webkit-transform: rotateY(180deg);
      /* Safari 和 Chrome */
      -o-transform: rotateY(180deg);
      /* Opera */
    }
    100% {
      transform: rotateY(360deg);
      -ms-transform: rotateY(360deg);
      /* IE 9 */
      -moz-transform: rotateY(360deg);
      /* Firefox */
      -webkit-transform: rotateY(360deg);
      /* Safari 和 Chrome */
      -o-transform: rotateY(360deg);
      /* Opera */
    }
  }

  @-moz-keyframes typing {
    0% {
      transform: rotateY(180deg);
      -ms-transform: rotateY(180deg);
      /* IE 9 */
      -moz-transform: rotateY(180deg);
      /* Firefox */
      -webkit-transform: rotateY(180deg);
      /* Safari 和 Chrome */
      -o-transform: rotateY(180deg);
      /* Opera */
    }
    25% {
      transform: rotateY(360deg);
      -ms-transform: rotateY(360deg);
      /* IE 9 */
      -moz-transform: rotateY(360deg);
      /* Firefox */
      -webkit-transform: rotateY(360deg);
      /* Safari 和 Chrome */
      -o-transform: rotateY(360deg);
      /* Opera */
    }
    50% {
      transform: rotateY(180deg);
      -ms-transform: rotateY(180deg);
      /* IE 9 */
      -moz-transform: rotateY(180deg);
      /* Firefox */
      -webkit-transform: rotateY(180deg);
      /* Safari 和 Chrome */
      -o-transform: rotateY(180deg);
      /* Opera */
    }
    100% {
      transform: rotateY(360deg);
      -ms-transform: rotateY(360deg);
      /* IE 9 */
      -moz-transform: rotateY(360deg);
      /* Firefox */
      -webkit-transform: rotateY(360deg);
      /* Safari 和 Chrome */
      -o-transform: rotateY(360deg);
      /* Opera */
    }
  }

  @keyframes typing {
    0% {
      transform: rotateY(180deg);
      -ms-transform: rotateY(180deg);
      /* IE 9 */
      -moz-transform: rotateY(180deg);
      /* Firefox */
      -webkit-transform: rotateY(180deg);
      /* Safari 和 Chrome */
      -o-transform: rotateY(180deg);
      /* Opera */
    }
    25% {
      transform: rotateY(360deg);
      -ms-transform: rotateY(360deg);
      /* IE 9 */
      -moz-transform: rotateY(360deg);
      /* Firefox */
      -webkit-transform: rotateY(360deg);
      /* Safari 和 Chrome */
      -o-transform: rotateY(360deg);
      /* Opera */
    }
    50% {
      transform: rotateY(180deg);
      -ms-transform: rotateY(180deg);
      /* IE 9 */
      -moz-transform: rotateY(180deg);
      /* Firefox */
      -webkit-transform: rotateY(180deg);
      /* Safari 和 Chrome */
      -o-transform: rotateY(180deg);
      /* Opera */
    }
    100% {
      transform: rotateY(360deg);
      -ms-transform: rotateY(360deg);
      /* IE 9 */
      -moz-transform: rotateY(360deg);
      /* Firefox */
      -webkit-transform: rotateY(360deg);
      /* Safari 和 Chrome */
      -o-transform: rotateY(360deg);
      /* Opera */
    }
  }
}